import EmailWhatsapp from '../components/EmailWhatsapp/EmailWhatsapp';
import Footer from '../components/Footer/Footer';

const Contact = () => {
  return (
    <div>
      <EmailWhatsapp />
      <Footer />
    </div>
  );
};

export default Contact;
