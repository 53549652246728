export const wordsList = {
  carro: [
    'Motor',
    'Porta',
    'Pneu',
    'Rodas',
    'Volante',
    'Ferrari',
    'Fusca',
    'Banco',
    'Cinto',
  ],
  fruta: [
    'Banana',
    'Abacate',
    'Ameixa',
    'Abacaxi',
    'Laranja',
    'Morango',
    'Cereja',
    'Acerola',
    'Amora',
    'Cacau',
  ],
  corpo: [
    'Braço',
    'Perna',
    'nariz',
    'Olhos',
    'Torso',
    'Pele',
    'Canela',
    'Joelho',
  ],
  computador: ['Mouse', 'Teclado', 'Monitor', 'Tela', 'Placa', 'Disco', 'Rede'],
  Instagram: [
    'Engajamento',
    'Seguidores',
    'Curtidas',
    'Hashtags',
    'Algoritmo',
    'Stories',
    'Reels',
    'Comentarios',
    'Parcerias',
    'Feed',
  ],
  alimento: [
    'Arroz',
    'Picanha',
    'Carne',
    'Leite',
    'Salada',
    'Tomate',
    'Batata',
    'Cenoura',
  ],
  esporte: ['Futebol', 'Malhar', 'Tenista', 'Boxe', 'karate', 'Golfe', 'Luta'],
  animal: [
    'Gato',
    'passaro',
    'Peixe',
    'Coelho',
    'Cavalo',
    'Rato',
    'Baleia',
    'Vaca',
    'Cobra',
    'Macaco',
  ],
};
